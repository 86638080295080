import React from "react";
import * as footerStyles from "./footer.module.scss";

function Footer() {
  return (
    <footer className={footerStyles.footer}>
      <div>
        <p>© CUSTARD RUNNING CLUB 2021</p>
      </div>
    </footer>
  );
}

export default Footer;
